// Overwrite Bootstrap's variables here
// You can find them in node_modules/bootstrap/scss/_variables.scss
// Copy the variables you need into this file, don't modify files under node_modules/

// Some example variables that you can uncomment:

// Enabling shadows and gradients
//$enable-shadows: true;
//$enable-gradients: true;



// #####################################################
// SPACE
// #####################################################
// Changing the border radius of buttons
//$border-radius: 15px;




// #####################################################
// TYPEFACE
// #####################################################

$font-family-sans-serif:      "Montserrat", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`

$paragraph-margin-bottom: 1rem; $paragraph-margin-bottom: $paragraph-margin-bottom !global; // 1rem

$font-size-lg:                ($font-size-base * 1.25);
$font-size-sm:                ($font-size-base * .75);

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            600;
$font-weight-bolder:          800;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.5;

$h1-font-size:                $font-size-base * 3.75;
$h2-font-size:                $font-size-base * 2.5;
$h3-font-size:                $font-size-base * 2.1;
$h4-font-size:                $font-size-base * 1.6;
$h5-font-size:                $font-size-base * 1.3;
$h6-font-size:                $font-size-base * 1;

$display1-size:                3rem;
$display2-size:                2.5rem;
$display3-size:                2rem;
$display4-size:                1.5rem;


// $display1-size: 6rem; $display1-size: $display1-size !global; // 6rem
// $display2-size: 5.5rem; $display2-size: $display2-size !global; // 5.5rem
// $display3-size: 4.5rem; $display3-size: $display3-size !global; // 4.5rem
// $display4-size: 3.5rem; $display4-size: $display4-size !global; // 3.5rem

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)); // ~20px


// $headings-margin-bottom:      $spacer;
$headings-font-family:        inherit;
$headings-font-weight:        400;
$headings-line-height:        1.2;
$headings-color:              inherit;



// #####################################################
// COLOR
// #####################################################



// Changing the body background and text
$body-bg: #F0F1F7;
$body-color: #333333;

//** Global textual link color.
$link-color: #007399;
$link-decoration: underline;

//** Link hover color set via `darken()` function.
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;

//** Navbar
$navbar-dark-color: #c2c5be;
$navbar-dark-hover-color: #99d1da;
$navbar-dark-active-color: #fff;
$navbar-dark-disabled-color: #888;



// ACCENT COLORS
$primary: #00608e;
$secondary: #d7d8de;

$success: #7cab1e;
$info: #2badc1;
$warning: #ec8115;
$danger: #cb3b34;
$light: #eaebf1;
$dark: #2e2e2e;

$alert-warning-bg: #fdefe1;
$alert-danger-bg: #f9eceb;



$theme-colors: (
  "light-gray": #f6f6f6,
  "medium-light": #e3e6ea,
  "medium": #acadb7,
  "medium-dark": #4c4b4b,
  "spark-pink": #da99b4,
  "light-blue": #99d1da
);

$gray-base:              #000;
$gray-darker:            lighten($gray-base, 13.5%); // #222
$gray-dark:              lighten($gray-base, 20%);   // #333
$gray:                   lighten($gray-base, 33.5%); // #555
$gray-light:             lighten($gray-base, 46.7%); // #777
$gray-lighter:           lighten($gray-base, 93.5%); // #f6f6f6

//** Placeholder text color
$input-color-placeholder:        #999;

// COLOR
$blue:    #99d1da;
// $indigo:  #6610f2;
// $purple:  #6f42c1;
// $pink:    #da99b4;
// $red:     #dc3545;
// $orange:  #fd7e14;
// $yellow:  #ffc107;
// $green:   #28a745;
// $teal:    #20c997;
// $cyan:    #17a2b8;

// GREY SCALE
$white:    #fff;
$gray-100: #f6f6f6;
$gray-200: #e6e6e6;
$gray-300: #dedede;
$gray-400: #cecece;
$gray-500: #bdbdbd;
$gray-600: #666666;
$gray-700: #444444;
$gray-800: #333333;
$gray-900: #222222;


//== printing
//
//##

$print-page-size:                   a3 !default;

//== Tooltips
//
//##

//** Tooltip max width
$tooltip-max-width:           320px;
//** Tooltip text color
$tooltip-color:               #fff;
//** Tooltip background color
$tooltip-bg:                  #444;
$tooltip-opacity:             1;

//** Tooltip arrow width
$tooltip-arrow-width:         10px;
//** Tooltip arrow color
$tooltip-arrow-color:         $tooltip-bg;



//== List group
//
//##

//** Background color on `.list-group-item`
$list-group-bg: transparent;
//** `.list-group-item` border color
$list-group-border: #ddd;


// Adding (!) an additional theme color (ex. classes btn-cool, bg-cool)
//$theme-colors: (
//  "cool": #4d3fa3
//);
