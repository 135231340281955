// Add additional styles here. For example, overwrite certain styles or add new components.
// Tip: You can use bootstrap's powerful mixins here!


//.alert-myalert {
//  @include alert-variant(#60667d, #1d1d1d, #f4fdff);
//}

//@each $color, $value in $theme-colors {
//  .alert-#{$color} {
//    box-shadow: 3px 3px theme-color-level($color, -3);
//  }
//}

@function set-notification-text-color($color) {
  @if (lightness($color) > 50) {
    @return #000000; // Lighter backgorund, return dark color
  } @else {
    @return #ffffff; // Darker background, return light color
  }
}

// Bootstrap 4 breakpoints & gutter
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
) !default;

$grid-gutter-width: 30px !default;

// number of cards per line for each breakpoint
$cards-per-line: (
    xs: 2,
    sm: 2,
    md: 2,
    lg: 3,
    xl: 5
);

$sizes: (
  15: 15%,
  25: 25%,
  35: 35%,
  50: 50%,
  75: 75%,
  100: 100%,
  60px: 60px,
  80px: 80px,
  100px: 100px
);
//
// Card Breakpoints
//
@each $name, $breakpoint in $grid-breakpoints {
	@media (min-width: $breakpoint) {
		.card-deck .card {
			flex: 0 0 calc(#{100/map-get($cards-per-line, $name)}% - #{$grid-gutter-width});
		}
	}
}
